<template>
    <svg viewBox="0 0 2487.1499 700.12988" width="2487.1499" height="700.12988" xml:space="preserve" overflow="hidden" version="1.1" id="svg57" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <defs id="defs5" />
        <g id="g764" transform="translate(-353.85023,-296)">
            <g id="g483">
                <g id="g484">
                    <path d="m 1209,303 h 78.03 L 1528,671.65 V 706 h -78.03 L 1209,341.926 Z" fill-rule="evenodd" fill-opacity="1" id="path7" />
                    <path d="m 1451,301 v 64.05 L 1528,480 V 302.642 Z" fill-rule="evenodd" fill-opacity="1" id="path9" />
                    <path d="M 1286,708 V 643.95 L 1209,529 v 177.36 z" fill-rule="evenodd" fill-opacity="1" id="path11" />
                    <path d="m 1692.46,301.07 c 136.92,-2.942 250.84,85.08 254.45,196.6 3.62,111.53 -104.45,204.32 -241.37,207.26 -2.18,0.05 -4.36,0.07 -6.53,0.07 L 1699,627.76 c 94.31,0 170.76,-55.86 170.76,-124.76 0,-68.91 -76.46,-124.76 -170.76,-124.76 -1.35,0 -2.69,0.01 -4.04,0.04 z" fill-rule="evenodd" fill-opacity="1" id="path13" />
                    <path d="m 1701,705 h -84 V 484 l 82.29,1.7 z" fill-rule="evenodd" fill-opacity="1" id="path15" />
                    <path d="m 1701,378.27 -82,1.73 v -78 l 76.9771,-0.83715 z" fill-rule="evenodd" fill-opacity="1" id="path17" />
                    <path id="rect19" d="m 1996,301 h 78.9998 V 705 H 1996 Z" />
                    <path id="rect21" d="m 2584,301 h 257 v 79.0001 h -257 z" />
                    <path id="rect23" d="m 2584,625 h 257 v 79.9998 h -257 z" />
                    <path id="rect25" d="m 2584,464 h 257 v 79.0001 h -257 z" />
                    <path d="m 770,707 35.77,-66 h 314.46 l 35.77,66 z" fill-rule="evenodd" fill-opacity="1" id="path27" fill="#64748b" />
                    <path d="m 815,613 35.23,-65 h 225.54 l 35.23,65 z" fill-rule="evenodd" fill-opacity="1" id="path29" fill="#64748b" />
                    <path d="m 857,520 35.23,-65 h 140.54 l 35.23,65 z" fill-rule="evenodd" fill-opacity="1" id="path31" fill="#64748b" />
                    <path d="m 901,427 35.77,-66 h 51.46 l 35.77,66 z" fill-rule="evenodd" fill-opacity="1" id="path33" fill="#64748b" />
                    <path d="m 944,334 18.5,-38 18.5,38 z" fill-rule="evenodd" fill-opacity="1" id="path35" fill="#64748b" />
                    <path d="m 730.27844,375.83789 -51.77539,49.39795 q -52.83203,-55.73779 -118.87207,-55.73779 -55.73779,0 -94.04101,38.03906 -38.03907,38.03906 -38.03907,93.77685 0,38.83155 16.90625,68.94581 16.90625,30.11425 47.81299,47.28466 30.90674,17.17041 68.68164,17.17041 32.22754,0 58.90772,-11.8872 26.68017,-12.15137 58.64355,-43.85059 l 50.19043,52.30371 q -43.0581,42.00147 -81.36133,58.3794 -38.30322,16.11377 -87.43701,16.11377 -90.60693,0 -148.45801,-57.32276 -57.58691,-57.58691 -57.58691,-147.40137 0,-58.11523 26.15186,-103.28662 26.41601,-45.17138 75.28564,-72.64404 49.13379,-27.47266 105.66406,-27.47266 48.07715,0 92.45606,20.34034 44.64306,20.34033 76.8706,57.85107 z" id="path506" />
                    <path d="m 2513.6733,375.83789 -51.7754,49.39795 q -52.832,-55.73779 -118.872,-55.73779 -55.7378,0 -94.041,38.03906 -38.0391,38.03906 -38.0391,93.77685 0,38.83155 16.9063,68.94581 16.9062,30.11425 47.8129,47.28466 30.9068,17.17041 68.6817,17.17041 32.2275,0 58.9077,-11.8872 26.6802,-12.15137 58.6435,-43.85059 l 50.1905,52.30371 q -43.0581,42.00147 -81.3613,58.3794 -38.3033,16.11377 -87.4371,16.11377 -90.6069,0 -148.458,-57.32276 -57.5869,-57.58691 -57.5869,-147.40137 0,-58.11523 26.1519,-103.28662 26.416,-45.17138 75.2856,-72.64404 49.1338,-27.47266 105.6641,-27.47266 48.0771,0 92.456,20.34034 44.6431,20.34033 76.8706,57.85107 z" id="path509" />
                    <g aria-label="Full text " id="text41" fill="#64748b">
                        <path d="m 489.18686,832.44824 -4.70117,3.60694 q -3.89063,-5.06592 -9.36182,-7.65967 -5.43066,-2.63428 -11.95557,-2.63428 -7.13281,0 -13.21191,3.44482 -6.0791,3.4043 -9.44287,9.19971 -3.32324,5.75488 -3.32324,12.96875 0,10.90186 7.45703,18.19678 7.49756,7.29492 18.88574,7.29492 12.52295,0 20.95264,-9.80762 l 4.70117,3.56641 q -4.45801,5.67383 -11.14502,8.79443 -6.64648,3.08008 -14.87354,3.08008 -15.64355,0 -24.68115,-10.41553 -7.57861,-8.79443 -7.57861,-21.23632 0,-13.09034 9.15918,-22.00635 9.19971,-8.95654 23.01953,-8.95654 8.34863,0 15.07617,3.32324 6.72754,3.28271 11.02344,9.24023 z" id="path512" />
                        <path d="m 521.08188,835.77148 q 10.17236,0 16.85937,7.37598 6.07911,6.72754 6.07911,15.92725 0,9.24023 -6.44385,16.17041 -6.40332,6.88965 -16.49463,6.88965 -10.13184,0 -16.57568,-6.88965 -6.40333,-6.93018 -6.40333,-16.17041 0,-9.15918 6.07911,-15.88672 6.68701,-7.41651 16.8999,-7.41651 z m 0,5.55225 q -7.05176,0 -12.11768,5.22803 -5.06592,5.22803 -5.06592,12.64453 0,4.78223 2.31006,8.91601 2.31006,4.13379 6.24121,6.40332 3.93116,2.22901 8.63233,2.22901 4.70117,0 8.63232,-2.22901 3.93116,-2.26953 6.24121,-6.40332 2.31006,-4.13378 2.31006,-8.91601 0,-7.4165 -5.10644,-12.64453 -5.06592,-5.22803 -12.07715,-5.22803 z" id="path514" />
                        <path d="m 553.70639,819.88477 h 5.67383 V 881 h -5.67383 z" id="path516" />
                        <path d="m 570.3226,819.88477 h 5.67383 V 881 h -5.67383 z" id="path518" />
                        <path d="M 631.72153,836.90625 V 881 h -5.59278 v -7.57861 q -3.5664,4.33642 -8.02441,6.5249 -4.41748,2.18848 -9.68604,2.18848 -9.36181,0 -16.0083,-6.76807 -6.60595,-6.80859 -6.60595,-16.53516 0,-9.52392 6.68701,-16.29199 6.68701,-6.76807 16.08935,-6.76807 5.43067,0 9.80762,2.31006 4.41748,2.31006 7.74072,6.93018 v -8.10547 z m -22.69532,4.33643 q -4.74169,0 -8.7539,2.35058 -4.01221,2.31006 -6.40332,6.5249 -2.35059,4.21485 -2.35059,8.91602 0,4.66064 2.39112,8.91602 2.39111,4.25537 6.40332,6.64648 4.05273,2.35059 8.67285,2.35059 4.66064,0 8.83496,-2.35059 4.17431,-2.35059 6.40332,-6.36279 2.26953,-4.01221 2.26953,-9.0376 0,-7.65967 -5.06592,-12.80664 -5.02539,-5.14697 -12.40137,-5.14697 z" id="path520" />
                        <path d="m 644.85239,881 v -61.11523 h 5.6333 v 24.60009 q 3.5664,-4.37695 7.98388,-6.5249 4.41748,-2.18848 9.68604,-2.18848 9.36182,0 15.96777,6.8086 6.64649,6.76806 6.64649,16.4541 0,9.56445 -6.68701,16.33252 -6.68702,6.76807 -16.08936,6.76807 -5.39014,0 -9.76709,-2.31006 -4.37695,-2.31006 -7.74072,-6.93018 V 881 Z m 22.69531,-4.33643 q 4.7417,0 8.75391,-2.31005 4.0122,-2.35059 6.36279,-6.56543 2.39111,-4.21485 2.39111,-8.91602 0,-4.70117 -2.39111,-8.95654 -2.39112,-4.25537 -6.44385,-6.60596 -4.01221,-2.39111 -8.5918,-2.39111 -4.66064,0 -8.87548,2.39111 -4.17432,2.35059 -6.44385,6.36279 -2.22901,4.01221 -2.22901,9.0376 0,7.65967 5.02539,12.80664 5.06592,5.14697 12.4419,5.14697 z" id="path522" />
                        <path d="m 721.97592,835.77148 q 10.17236,0 16.85938,7.37598 6.0791,6.72754 6.0791,15.92725 0,9.24023 -6.44385,16.17041 -6.40332,6.88965 -16.49463,6.88965 -10.13183,0 -16.57568,-6.88965 -6.40332,-6.93018 -6.40332,-16.17041 0,-9.15918 6.0791,-15.88672 6.68701,-7.41651 16.8999,-7.41651 z m 0,5.55225 q -7.05176,0 -12.11767,5.22803 -5.06592,5.22803 -5.06592,12.64453 0,4.78223 2.31006,8.91601 2.31006,4.13379 6.24121,6.40332 3.93115,2.22901 8.63232,2.22901 4.70117,0 8.63233,-2.22901 3.93115,-2.26953 6.24121,-6.40332 2.31006,-4.13378 2.31006,-8.91601 0,-7.4165 -5.10645,-12.64453 -5.06592,-5.22803 -12.07715,-5.22803 z" id="path524" />
                        <path d="m 753.30356,836.90625 h 5.79541 v 6.44385 q 2.59375,-3.80957 5.47119,-5.67383 2.87744,-1.90479 5.99805,-1.90479 2.35058,0 5.02539,1.49952 l -2.9585,4.78222 q -1.7832,-0.77002 -2.99902,-0.77002 -2.83692,0 -5.47119,2.35059 -2.63428,2.31006 -4.01221,7.21387 -1.05371,3.76904 -1.05371,15.23828 V 881 h -5.79541 z" id="path526" />
                        <path d="M 824.34799,836.90625 V 881 h -5.59277 v -7.57861 q -3.56641,4.33642 -8.02442,6.5249 -4.41748,2.18848 -9.68603,2.18848 -9.36182,0 -16.0083,-6.76807 -6.60596,-6.80859 -6.60596,-16.53516 0,-9.52392 6.68701,-16.29199 6.68701,-6.76807 16.08936,-6.76807 5.43066,0 9.80762,2.31006 4.41748,2.31006 7.74072,6.93018 v -8.10547 z m -22.69531,4.33643 q -4.7417,0 -8.75391,2.35058 -4.0122,2.31006 -6.40332,6.5249 -2.35058,4.21485 -2.35058,8.91602 0,4.66064 2.39111,8.91602 2.39111,4.25537 6.40332,6.64648 4.05273,2.35059 8.67285,2.35059 4.66065,0 8.83496,-2.35059 4.17432,-2.35059 6.40332,-6.36279 2.26953,-4.01221 2.26953,-9.0376 0,-7.65967 -5.06591,-12.80664 -5.02539,-5.14697 -12.40137,-5.14697 z" id="path528" />
                        <path d="m 842.05844,820.5332 h 5.71436 v 16.37305 h 8.99707 v 4.86328 H 847.7728 V 881 h -5.71436 v -39.23047 h -7.74072 v -4.86328 h 7.74072 z" id="path530" />
                        <path d="m 867.22592,818.75 q 1.94531,0 3.32324,1.37793 1.37793,1.37793 1.37793,3.32324 0,1.90479 -1.37793,3.28272 -1.37793,1.37793 -3.32324,1.37793 -1.90478,0 -3.28271,-1.37793 -1.37793,-1.37793 -1.37793,-3.28272 0,-1.94531 1.37793,-3.32324 1.37793,-1.37793 3.28271,-1.37793 z m -2.83691,18.15625 h 5.71435 V 881 h -5.71435 z" id="path532" />
                        <path d="m 877.68198,836.90625 h 6.03857 l 14.75195,32.09766 14.63037,-32.09766 h 6.07911 L 898.99936,881 h -1.01318 z" id="path534" />
                        <path d="m 964.97787,866.36963 4.78223,2.51269 q -2.35058,4.62012 -5.43066,7.45704 -3.08008,2.83691 -6.93018,4.33642 -3.8501,1.45899 -8.71338,1.45899 -10.78027,0 -16.85937,-7.05176 -6.0791,-7.09229 -6.0791,-16.0083 0,-8.38916 5.14697,-14.95459 6.5249,-8.34864 17.46728,-8.34864 11.26661,0 17.99414,8.55127 4.78223,6.03858 4.86329,15.07618 h -39.63575 q 0.16211,7.70019 4.90381,12.64453 4.7417,4.90381 11.7124,4.90381 3.36377,0 6.52491,-1.1753 3.20166,-1.17529 5.43066,-3.1206 2.229,-1.94532 4.82275,-6.28174 z m 0,-11.87451 q -1.13476,-4.53907 -3.32324,-7.2544 -2.14795,-2.71533 -5.71435,-4.37695 -3.56641,-1.66162 -7.49756,-1.66162 -6.48438,0 -11.14502,4.17431 -3.4043,3.03956 -5.14697,9.11866 z" id="path536" />
                        <path d="M 1026.3513,821.38428 1054.1531,881 h -6.4033 l -9.3619,-19.61523 h -25.6943 L 1003.3723,881 h -6.64647 l 28.16647,-59.61572 z m -0.7295,12.64453 -10.2534,21.60107 h 20.3853 z" id="path539" />
                        <path d="m 1063.4338,881 v -59.61572 h 1.2969 l 39.6358,45.63379 v -45.63379 h 5.8764 V 881 h -1.3374 L 1069.594,835.93359 V 881 Z" id="path541" />
                        <path d="m 1123.8601,836.90625 h 5.6738 v 7.90283 q 3.4043,-4.53906 7.5381,-6.76806 4.1338,-2.26954 8.9971,-2.26954 4.9443,0 8.7539,2.5127 3.8501,2.51269 5.6738,6.76807 1.8238,4.25537 1.8238,13.25244 V 881 h -5.6333 v -21.03369 q 0,-7.61914 -0.6485,-10.17236 -1.0132,-4.37696 -3.8096,-6.56543 -2.7963,-2.22901 -7.2949,-2.22901 -5.1469,0 -9.2402,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8106,3.28271 -0.8106,11.99609 V 881 h -5.6738 z" id="path543" />
                        <path d="m 1195.3503,835.77148 q 10.1724,0 16.8594,7.37598 6.0791,6.72754 6.0791,15.92725 0,9.24023 -6.4438,16.17041 -6.4033,6.88965 -16.4947,6.88965 -10.1318,0 -16.5756,-6.88965 -6.4034,-6.93018 -6.4034,-16.17041 0,-9.15918 6.0791,-15.88672 6.6871,-7.41651 16.8999,-7.41651 z m 0,5.55225 q -7.0517,0 -12.1176,5.22803 -5.066,5.22803 -5.066,12.64453 0,4.78223 2.3101,8.91601 2.3101,4.13379 6.2412,6.40332 3.9312,2.22901 8.6323,2.22901 4.7012,0 8.6324,-2.22901 3.9311,-2.26953 6.2412,-6.40332 2.31,-4.13378 2.31,-8.91601 0,-7.4165 -5.1064,-12.64453 -5.0659,-5.22803 -12.0772,-5.22803 z" id="path545" />
                        <path d="m 1233.7703,820.5332 h 5.7143 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 881 h -5.7143 v -39.23047 h -7.7408 v -4.86328 h 7.7408 z" id="path547" />
                        <path d="M 1300.8835,836.90625 V 881 h -5.5927 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8076,2.31006 4.4175,2.31006 7.7408,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3911,4.25537 6.4034,6.64648 4.0527,2.35059 8.6728,2.35059 4.6606,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path549" />
                        <path d="m 1318.594,820.5332 h 5.7143 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 881 h -5.7143 v -39.23047 h -7.7407 v -4.86328 h 7.7407 z" id="path551" />
                        <path d="m 1343.7615,818.75 q 1.9453,0 3.3232,1.37793 1.3779,1.37793 1.3779,3.32324 0,1.90479 -1.3779,3.28272 -1.3779,1.37793 -3.3232,1.37793 -1.9048,0 -3.2827,-1.37793 -1.378,-1.37793 -1.378,-3.28272 0,-1.94531 1.378,-3.32324 1.3779,-1.37793 3.2827,-1.37793 z m -2.8369,18.15625 h 5.7143 V 881 h -5.7143 z" id="path553" />
                        <path d="m 1379.2634,835.77148 q 10.1724,0 16.8594,7.37598 6.0791,6.72754 6.0791,15.92725 0,9.24023 -6.4438,16.17041 -6.4034,6.88965 -16.4947,6.88965 -10.1318,0 -16.5757,-6.88965 -6.4033,-6.93018 -6.4033,-16.17041 0,-9.15918 6.0791,-15.88672 6.687,-7.41651 16.8999,-7.41651 z m 0,5.55225 q -7.0517,0 -12.1176,5.22803 -5.066,5.22803 -5.066,12.64453 0,4.78223 2.3101,8.91601 2.3101,4.13379 6.2412,6.40332 3.9312,2.22901 8.6323,2.22901 4.7012,0 8.6324,-2.22901 3.9311,-2.26953 6.2412,-6.40332 2.31,-4.13378 2.31,-8.91601 0,-7.4165 -5.1064,-12.64453 -5.0659,-5.22803 -12.0772,-5.22803 z" id="path555" />
                        <path d="m 1412.739,836.90625 h 5.6738 v 7.90283 q 3.4043,-4.53906 7.5381,-6.76806 4.1338,-2.26954 8.9971,-2.26954 4.9443,0 8.7539,2.5127 3.8501,2.51269 5.6738,6.76807 1.8238,4.25537 1.8238,13.25244 V 881 h -5.6333 v -21.03369 q 0,-7.61914 -0.6485,-10.17236 -1.0132,-4.37696 -3.8095,-6.56543 -2.7964,-2.22901 -7.295,-2.22901 -5.1469,0 -9.2402,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8106,3.28271 -0.8106,11.99609 V 881 h -5.6738 z" id="path557" />
                        <path d="m 1484.9644,881 v -59.61572 h 12.3608 q 13.374,0 19.4126,2.14795 8.6728,3.03955 13.5361,10.53711 4.9038,7.49755 4.9038,17.87255 0,8.95655 -3.8906,15.76514 -3.8501,6.80859 -10.0508,10.05078 Q 1515.0762,881 1503.9312,881 Z m 5.7143,-5.6333 h 6.8897 q 12.3608,0 17.143,-1.54004 6.7276,-2.18848 10.5777,-7.8623 3.8501,-5.71436 3.8501,-13.94141 0,-8.63233 -4.1744,-14.75195 -4.1743,-6.11963 -11.6313,-8.38916 -5.5928,-1.70215 -18.4399,-1.70215 h -4.2149 z" id="path560" />
                        <path d="M 1589.7275,836.90625 V 881 h -5.5927 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8076,2.31006 4.4175,2.31006 7.7408,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3911,4.25537 6.4033,6.64648 4.0528,2.35059 8.6729,2.35059 4.6606,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path562" />
                        <path d="m 1607.438,820.5332 h 5.7143 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 881 h -5.7143 v -39.23047 h -7.7407 v -4.86328 h 7.7407 z" id="path564" />
                        <path d="M 1674.5513,836.90625 V 881 h -5.5928 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0894,-6.76807 5.4306,0 9.8076,2.31006 4.4175,2.31006 7.7407,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0123,2.31006 -6.4034,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3912,8.91602 2.3911,4.25537 6.4033,6.64648 4.0527,2.35059 8.6728,2.35059 4.6607,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4013,-5.14697 z" id="path566" />
                        <path d="m 1687.6821,881 v -61.11523 h 5.6333 v 24.60009 q 3.5664,-4.37695 7.9839,-6.5249 4.4175,-2.18848 9.6861,-2.18848 9.3618,0 15.9677,6.8086 6.6465,6.76806 6.6465,16.4541 0,9.56445 -6.687,16.33252 -6.687,6.76807 -16.0894,6.76807 -5.3901,0 -9.767,-2.31006 -4.377,-2.31006 -7.7408,-6.93018 V 881 Z m 22.6953,-4.33643 q 4.7417,0 8.7539,-2.31005 4.0123,-2.35059 6.3628,-6.56543 2.3912,-4.21485 2.3912,-8.91602 0,-4.70117 -2.3912,-8.95654 -2.3911,-4.25537 -6.4438,-6.60596 -4.0122,-2.39111 -8.5918,-2.39111 -4.6606,0 -8.8755,2.39111 -4.1743,2.35059 -6.4438,6.36279 -2.229,4.01221 -2.229,9.0376 0,7.65967 5.0253,12.80664 5.066,5.14697 12.4419,5.14697 z" id="path568" />
                        <path d="M 1787.8657,836.90625 V 881 h -5.5928 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4174,2.18848 -9.686,2.18848 -9.3618,0 -16.0083,-6.76807 -6.606,-6.80859 -6.606,-16.53516 0,-9.52392 6.6871,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8076,2.31006 4.4175,2.31006 7.7407,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3911,4.25537 6.4033,6.64648 4.0528,2.35059 8.6729,2.35059 4.6606,0 8.8349,-2.35059 4.1744,-2.35059 6.4034,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path570" />
                        <path d="m 1822.8003,841.729 -3.6475,3.76905 q -4.539,-4.41748 -8.8755,-4.41748 -2.7558,0 -4.7417,1.82373 -1.9453,1.82373 -1.9453,4.25537 0,2.14795 1.6211,4.09326 1.6211,1.98584 6.8086,4.66064 6.3223,3.28272 8.5918,6.32227 2.229,3.08008 2.229,6.93018 0,5.43066 -3.8095,9.1997 -3.8096,3.76905 -9.524,3.76905 -3.8095,0 -7.2949,-1.66163 -3.4448,-1.66162 -5.7144,-4.57959 l 3.5665,-4.05273 q 4.3364,4.90381 9.1997,4.90381 3.4043,0 5.7954,-2.18848 2.3911,-2.18847 2.3911,-5.14697 0,-2.43164 -1.5806,-4.33643 -1.5805,-1.86425 -7.1328,-4.70117 -5.9575,-3.08008 -8.1055,-6.0791 -2.1479,-2.99902 -2.1479,-6.84912 0,-5.02539 3.4043,-8.34863 3.4448,-3.32325 8.6728,-3.32325 6.0791,0 12.2393,5.95752 z" id="path572" />
                        <path d="m 1869.9741,866.36963 4.7822,2.51269 q -2.3505,4.62012 -5.4306,7.45704 -3.0801,2.83691 -6.9302,4.33642 -3.8501,1.45899 -8.7134,1.45899 -10.7802,0 -16.8593,-7.05176 -6.0791,-7.09229 -6.0791,-16.0083 0,-8.38916 5.1469,-14.95459 6.5249,-8.34864 17.4673,-8.34864 11.2666,0 17.9942,8.55127 4.7822,6.03858 4.8632,15.07618 h -39.6357 q 0.1621,7.70019 4.9038,12.64453 4.7417,4.90381 11.7124,4.90381 3.3638,0 6.5249,-1.1753 3.2017,-1.17529 5.4307,-3.1206 2.229,-1.94532 4.8227,-6.28174 z m 0,-11.87451 q -1.1347,-4.53907 -3.3232,-7.2544 -2.148,-2.71533 -5.7144,-4.37695 -3.5664,-1.66162 -7.4975,-1.66162 -6.4844,0 -11.1451,4.17431 -3.4043,3.03956 -5.1469,9.11866 z" id="path574" />
                        <path d="m 1900.8848,836.90625 h 5.8359 l 13.4956,32.0166 14.1441,-32.0166 h 1.0131 l 14.1036,32.0166 13.7387,-32.0166 h 5.917 L 1950.0039,881 h -1.0942 L 1934.8872,849.4292 1920.7432,881 h -1.0537 z" id="path577" />
                        <path d="m 1977.8057,818.75 q 1.9453,0 3.3232,1.37793 1.3779,1.37793 1.3779,3.32324 0,1.90479 -1.3779,3.28272 -1.3779,1.37793 -3.3232,1.37793 -1.9048,0 -3.2828,-1.37793 -1.3779,-1.37793 -1.3779,-3.28272 0,-1.94531 1.3779,-3.32324 1.378,-1.37793 3.2828,-1.37793 z m -2.8369,18.15625 h 5.7143 V 881 h -5.7143 z" id="path579" />
                        <path d="m 1997.3804,820.5332 h 5.7143 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 881 h -5.7143 v -39.23047 h -7.7408 v -4.86328 h 7.7408 z" id="path581" />
                        <path d="m 2020.562,819.88477 h 5.6738 v 24.92431 q 3.4449,-4.53906 7.5787,-6.76806 4.1337,-2.26954 8.9565,-2.26954 4.9443,0 8.7539,2.5127 3.8501,2.51269 5.6738,6.76807 1.8238,4.21484 1.8238,13.25244 V 881 h -5.6739 v -21.03369 q 0,-7.61914 -0.6079,-10.17236 -1.0537,-4.37696 -3.8501,-6.56543 -2.7558,-2.22901 -7.2544,-2.22901 -5.1469,0 -9.2402,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8106,3.24218 -0.8106,11.99609 V 881 h -5.6738 z" id="path583" />
                        <path d="M 2137.5439,836.90625 V 881 h -5.5927 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8076,2.31006 4.4175,2.31006 7.7408,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3911,4.25537 6.4034,6.64648 4.0527,2.35059 8.6728,2.35059 4.6607,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path586" />
                        <path d="m 2150.3101,836.90625 h 5.6738 v 7.90283 q 3.4043,-4.53906 7.5381,-6.76806 4.1338,-2.26954 8.997,-2.26954 4.9444,0 8.7539,2.5127 3.8501,2.51269 5.6739,6.76807 1.8237,4.25537 1.8237,13.25244 V 881 h -5.6333 v -21.03369 q 0,-7.61914 -0.6484,-10.17236 -1.0132,-4.37696 -3.8096,-6.56543 -2.7964,-2.22901 -7.2949,-2.22901 -5.147,0 -9.2403,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8105,3.28271 -0.8105,11.99609 V 881 h -5.6738 z" id="path588" />
                        <path d="m 2223.1733,821.38428 h 5.9576 V 881 h -5.9576 z" id="path591" />
                        <path d="m 2241.8564,836.90625 h 5.6739 v 7.90283 q 3.4043,-4.53906 7.5381,-6.76806 4.1337,-2.26954 8.997,-2.26954 4.9444,0 8.7539,2.5127 3.8501,2.51269 5.6739,6.76807 1.8237,4.25537 1.8237,13.25244 V 881 h -5.6333 v -21.03369 q 0,-7.61914 -0.6484,-10.17236 -1.0132,-4.37696 -3.8096,-6.56543 -2.7964,-2.22901 -7.2949,-2.22901 -5.147,0 -9.2403,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8105,3.28271 -0.8105,11.99609 V 881 h -5.6739 z" id="path593" />
                        <path d="m 2297.4194,820.5332 h 5.7144 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 881 h -5.7144 v -39.23047 h -7.7407 v -4.86328 h 7.7407 z" id="path595" />
                        <path d="m 2357.7241,866.36963 4.7822,2.51269 q -2.3505,4.62012 -5.4306,7.45704 -3.0801,2.83691 -6.9302,4.33642 -3.8501,1.45899 -8.7134,1.45899 -10.7802,0 -16.8593,-7.05176 -6.0791,-7.09229 -6.0791,-16.0083 0,-8.38916 5.1469,-14.95459 6.5249,-8.34864 17.4673,-8.34864 11.2666,0 17.9942,8.55127 4.7822,6.03858 4.8632,15.07618 h -39.6357 q 0.1621,7.70019 4.9038,12.64453 4.7417,4.90381 11.7124,4.90381 3.3638,0 6.5249,-1.1753 3.2017,-1.17529 5.4307,-3.1206 2.229,-1.94532 4.8227,-6.28174 z m 0,-11.87451 q -1.1347,-4.53907 -3.3232,-7.2544 -2.148,-2.71533 -5.7144,-4.37695 -3.5664,-1.66162 -7.4975,-1.66162 -6.4844,0 -11.1451,4.17431 -3.4043,3.03956 -5.1469,9.11866 z" id="path597" />
                        <path d="m 2372.395,836.90625 h 5.7954 v 6.44385 q 2.5938,-3.80957 5.4712,-5.67383 2.8775,-1.90479 5.9981,-1.90479 2.3506,0 5.0254,1.49952 l -2.9585,4.78222 q -1.7832,-0.77002 -2.9991,-0.77002 -2.8369,0 -5.4712,2.35059 -2.6342,2.31006 -4.0122,7.21387 -1.0537,3.76904 -1.0537,15.23828 V 881 h -5.7954 z" id="path599" />
                        <path d="m 2399.5889,836.90625 h 5.6333 v 8.10547 q 3.3637,-4.62012 7.7407,-6.93018 4.3769,-2.31006 9.7671,-2.31006 9.4023,0 16.0893,6.76807 6.687,6.76807 6.687,16.29199 0,9.72657 -6.6464,16.53516 -6.606,6.76807 -15.9678,6.76807 -5.2686,0 -9.686,-2.18848 -4.4175,-2.18848 -7.9839,-6.5249 v 23.70849 h -5.6333 z m 22.6953,4.33643 q -7.376,0 -12.4419,5.14697 -5.0254,5.14697 -5.0254,12.80664 0,5.02539 2.229,9.0376 2.2695,4.0122 6.4438,6.36279 4.2149,2.35059 8.8755,2.35059 4.5796,0 8.5918,-2.35059 4.0528,-2.39111 6.4439,-6.64648 2.3911,-4.25538 2.3911,-8.91602 0,-4.70117 -2.3911,-8.91602 -2.3506,-4.21484 -6.3628,-6.5249 -4.0122,-2.35058 -8.7539,-2.35058 z" id="path601" />
                        <path d="m 2453.9766,836.90625 h 5.7954 v 6.44385 q 2.5937,-3.80957 5.4712,-5.67383 2.8774,-1.90479 5.998,-1.90479 2.3506,0 5.0254,1.49952 l -2.9585,4.78222 q -1.7832,-0.77002 -2.999,-0.77002 -2.8369,0 -5.4712,2.35059 -2.6343,2.31006 -4.0122,7.21387 -1.0537,3.76904 -1.0537,15.23828 V 881 h -5.7954 z" id="path603" />
                        <path d="m 2517.8882,866.36963 4.7822,2.51269 q -2.3506,4.62012 -5.4307,7.45704 -3.08,2.83691 -6.9301,4.33642 -3.8501,1.45899 -8.7134,1.45899 -10.7803,0 -16.8594,-7.05176 -6.0791,-7.09229 -6.0791,-16.0083 0,-8.38916 5.147,-14.95459 6.5249,-8.34864 17.4673,-8.34864 11.2666,0 17.9941,8.55127 4.7822,6.03858 4.8633,15.07618 h -39.6357 q 0.1621,7.70019 4.9038,12.64453 4.7417,4.90381 11.7124,4.90381 3.3637,0 6.5249,-1.1753 3.2016,-1.17529 5.4306,-3.1206 2.229,-1.94532 4.8228,-6.28174 z m 0,-11.87451 q -1.1348,-4.53907 -3.3233,-7.2544 -2.1479,-2.71533 -5.7143,-4.37695 -3.5664,-1.66162 -7.4976,-1.66162 -6.4843,0 -11.145,4.17431 -3.4043,3.03956 -5.147,9.11866 z" id="path605" />
                        <path d="m 2539.6514,820.5332 h 5.7143 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 881 h -5.7143 v -39.23047 h -7.7408 v -4.86328 h 7.7408 z" id="path607" />
                        <path d="M 2606.7646,836.90625 V 881 h -5.5927 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8077,2.31006 4.4174,2.31006 7.7407,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3912,4.25537 6.4034,6.64648 4.0527,2.35059 8.6728,2.35059 4.6607,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path609" />
                        <path d="m 2624.4751,820.5332 h 5.7144 v 16.37305 h 8.997 v 4.86328 h -8.997 V 881 h -5.7144 v -39.23047 h -7.7407 v -4.86328 h 7.7407 z" id="path611" />
                        <path d="m 2649.6426,818.75 q 1.9453,0 3.3232,1.37793 1.378,1.37793 1.378,3.32324 0,1.90479 -1.378,3.28272 -1.3779,1.37793 -3.3232,1.37793 -1.9048,0 -3.2827,-1.37793 -1.378,-1.37793 -1.378,-3.28272 0,-1.94531 1.378,-3.32324 1.3779,-1.37793 3.2827,-1.37793 z m -2.8369,18.15625 h 5.7143 V 881 h -5.7143 z" id="path613" />
                        <path d="m 2685.1445,835.77148 q 10.1724,0 16.8594,7.37598 6.0791,6.72754 6.0791,15.92725 0,9.24023 -6.4438,16.17041 -6.4034,6.88965 -16.4947,6.88965 -10.1318,0 -16.5757,-6.88965 -6.4033,-6.93018 -6.4033,-16.17041 0,-9.15918 6.0791,-15.88672 6.687,-7.41651 16.8999,-7.41651 z m 0,5.55225 q -7.0517,0 -12.1176,5.22803 -5.066,5.22803 -5.066,12.64453 0,4.78223 2.3101,8.91601 2.3101,4.13379 6.2412,6.40332 3.9312,2.22901 8.6323,2.22901 4.7012,0 8.6324,-2.22901 3.9311,-2.26953 6.2412,-6.40332 2.31,-4.13378 2.31,-8.91601 0,-7.4165 -5.1064,-12.64453 -5.0659,-5.22803 -12.0772,-5.22803 z" id="path615" />
                        <path d="m 2718.6201,836.90625 h 5.6738 v 7.90283 q 3.4043,-4.53906 7.5381,-6.76806 4.1338,-2.26954 8.9971,-2.26954 4.9443,0 8.7539,2.5127 3.8501,2.51269 5.6738,6.76807 1.8238,4.25537 1.8238,13.25244 V 881 h -5.6333 v -21.03369 q 0,-7.61914 -0.6485,-10.17236 -1.0132,-4.37696 -3.8095,-6.56543 -2.7964,-2.22901 -7.295,-2.22901 -5.1469,0 -9.2402,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8106,3.28271 -0.8106,11.99609 V 881 h -5.6738 z" id="path617" />
                        <path d="M 1010.0439,935.90625 V 980 h -5.5928 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.41748,2.18848 -9.68604,2.18848 -9.36181,0 -16.0083,-6.76807 -6.60596,-6.80859 -6.60596,-16.53516 0,-9.52392 6.68702,-16.29199 6.68701,-6.76807 16.08935,-6.76807 5.43067,0 9.80762,2.31006 4.41751,2.31006 7.74071,6.93018 v -8.10547 z m -22.69533,4.33643 q -4.7417,0 -8.7539,2.35058 -4.01221,2.31006 -6.40332,6.5249 -2.35059,4.21485 -2.35059,8.91602 0,4.66064 2.39111,8.91602 2.39112,4.25537 6.40332,6.64648 4.05274,2.35059 8.67285,2.35059 4.66065,0 8.83497,-2.35059 4.17429,-2.35059 6.40329,-6.36279 2.2696,-4.01221 2.2696,-9.0376 0,-7.65967 -5.06596,-12.80664 -5.02539,-5.14697 -12.40137,-5.14697 z" id="path737" />
                        <path d="m 1022.81,935.90625 h 5.6738 v 7.90283 q 3.4043,-4.53906 7.5381,-6.76806 4.1338,-2.26954 8.9971,-2.26954 4.9443,0 8.7539,2.5127 3.8501,2.51269 5.6738,6.76807 1.8237,4.25537 1.8237,13.25244 V 980 h -5.6333 v -21.03369 q 0,-7.61914 -0.6484,-10.17236 -1.0132,-4.37696 -3.8096,-6.56543 -2.7963,-2.22901 -7.2949,-2.22901 -5.147,0 -9.2402,3.4043 -4.0528,3.4043 -5.3496,8.42969 -0.8106,3.28271 -0.8106,11.99609 V 980 h -5.6738 z" id="path739" />
                        <path d="M 1117.4413,918.88477 V 980 h -5.5927 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8076,2.31006 4.4175,2.31006 7.7408,6.93018 v -25.12695 z m -22.6953,21.35791 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3911,4.25537 6.4034,6.64648 4.0527,2.35059 8.6728,2.35059 4.6606,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path741" />
                        <path d="m 1209.7221,931.44824 -4.7012,3.60694 q -3.8906,-5.06592 -9.3618,-7.65967 -5.4306,-2.63428 -11.9555,-2.63428 -7.1329,0 -13.212,3.44482 -6.0791,3.4043 -9.4428,9.19971 -3.3233,5.75488 -3.3233,12.96875 0,10.90186 7.4571,18.19678 7.4975,7.29492 18.8857,7.29492 12.5229,0 20.9526,-9.80762 l 4.7012,3.56641 q -4.458,5.67383 -11.145,8.79443 -6.6465,3.08008 -14.8735,3.08008 -15.6436,0 -24.6812,-10.41553 -7.5786,-8.79443 -7.5786,-21.23632 0,-13.09034 9.1592,-22.00635 9.1997,-8.95654 23.0195,-8.95654 8.3486,0 15.0762,3.32324 6.7275,3.28271 11.0234,9.24023 z" id="path743" />
                        <path d="m 1219.8945,918.88477 h 5.6738 V 980 h -5.6738 z" id="path745" />
                        <path d="M 1281.2934,935.90625 V 980 h -5.5928 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.686,2.18848 -9.3618,0 -16.0083,-6.76807 -6.606,-6.80859 -6.606,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0894,-6.76807 5.4306,0 9.8076,2.31006 4.4175,2.31006 7.7407,6.93018 v -8.10547 z m -22.6953,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3911,4.25537 6.4033,6.64648 4.0527,2.35059 8.6729,2.35059 4.6606,0 8.8349,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2696,-4.01221 2.2696,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path747" />
                        <path d="m 1316.228,940.729 -3.6475,3.76905 q -4.5391,-4.41748 -8.8755,-4.41748 -2.7558,0 -4.7417,1.82373 -1.9453,1.82373 -1.9453,4.25537 0,2.14795 1.6211,4.09326 1.6211,1.98584 6.8086,4.66064 6.3223,3.28272 8.5918,6.32227 2.229,3.08008 2.229,6.93018 0,5.43066 -3.8096,9.1997 -3.8095,3.76905 -9.5239,3.76905 -3.8096,0 -7.2949,-1.66163 -3.4448,-1.66162 -5.7144,-4.57959 l 3.5664,-4.05273 q 4.3365,4.90381 9.1997,4.90381 3.4043,0 5.7954,-2.18848 2.3912,-2.18847 2.3912,-5.14697 0,-2.43164 -1.5806,-4.33643 -1.5806,-1.86425 -7.1328,-4.70117 -5.9575,-3.08008 -8.1055,-6.0791 -2.1479,-2.99902 -2.1479,-6.84912 0,-5.02539 3.4043,-8.34863 3.4448,-3.32325 8.6728,-3.32325 6.0791,0 12.2393,5.95752 z" id="path749" />
                        <path d="m 1348.4472,940.729 -3.6475,3.76905 q -4.539,-4.41748 -8.8754,-4.41748 -2.7559,0 -4.7417,1.82373 -1.9454,1.82373 -1.9454,4.25537 0,2.14795 1.6211,4.09326 1.6211,1.98584 6.8086,4.66064 6.3223,3.28272 8.5918,6.32227 2.229,3.08008 2.229,6.93018 0,5.43066 -3.8095,9.1997 -3.8096,3.76905 -9.524,3.76905 -3.8095,0 -7.2949,-1.66163 -3.4448,-1.66162 -5.7143,-4.57959 l 3.5664,-4.05273 q 4.3364,4.90381 9.1997,4.90381 3.4043,0 5.7954,-2.18848 2.3911,-2.18847 2.3911,-5.14697 0,-2.43164 -1.5806,-4.33643 -1.5805,-1.86425 -7.1328,-4.70117 -5.9575,-3.08008 -8.1055,-6.0791 -2.1479,-2.99902 -2.1479,-6.84912 0,-5.02539 3.4043,-8.34863 3.4448,-3.32325 8.6728,-3.32325 6.0791,0 12.2393,5.95752 z" id="path751" />
                        <path d="m 1360.4838,917.75 q 1.9453,0 3.3233,1.37793 1.3779,1.37793 1.3779,3.32324 0,1.90479 -1.3779,3.28272 -1.378,1.37793 -3.3233,1.37793 -1.9048,0 -3.2827,-1.37793 -1.3779,-1.37793 -1.3779,-3.28272 0,-1.94531 1.3779,-3.32324 1.3779,-1.37793 3.2827,-1.37793 z m -2.8369,18.15625 h 5.7144 V 980 h -5.7144 z" id="path753" />
                        <path d="m 1393.2704,918.88477 v 5.55224 q -3.1206,-1.3374 -5.3496,-1.3374 -1.6616,0 -2.7558,0.68896 -1.0943,0.64844 -1.378,1.54004 -0.2836,0.85108 -0.2836,4.62012 v 5.95752 h 9.1997 v 4.86328 h -9.2403 V 980 h -5.6738 v -39.23047 h -5.7954 v -4.86328 h 5.7954 v -6.88965 q 0,-4.7417 0.8511,-6.76806 0.8916,-2.02637 2.9585,-3.24219 2.1074,-1.25635 5.0659,-1.25635 2.7558,0 6.6059,1.13477 z" id="path755" />
                        <path d="m 1403.1591,917.75 q 1.9453,0 3.3233,1.37793 1.3779,1.37793 1.3779,3.32324 0,1.90479 -1.3779,3.28272 -1.378,1.37793 -3.3233,1.37793 -1.9048,0 -3.2827,-1.37793 -1.3779,-1.37793 -1.3779,-3.28272 0,-1.94531 1.3779,-3.32324 1.3779,-1.37793 3.2827,-1.37793 z m -2.8369,18.15625 h 5.7144 V 980 h -5.7144 z" id="path757" />
                        <path d="m 1460.5864,945.06543 -4.4986,2.79639 q -5.8359,-7.74073 -15.9272,-7.74073 -8.065,0 -13.4146,5.1875 -5.309,5.1875 -5.309,12.60401 0,4.82275 2.4316,9.07812 2.4722,4.25537 6.7275,6.60596 4.2959,2.35059 9.605,2.35059 9.7266,0 15.8867,-7.74073 l 4.4986,2.9585 q -3.1612,4.7417 -8.5108,7.37598 -5.3091,2.59375 -12.1177,2.59375 -10.456,0 -17.3457,-6.64649 -6.8896,-6.64648 -6.8896,-16.17041 0,-6.40332 3.2017,-11.87451 3.2421,-5.51172 8.8754,-8.5918 5.6333,-3.08008 12.604,-3.08008 4.377,0 8.4297,1.33741 4.0933,1.3374 6.9302,3.48535 2.8369,2.14795 4.8228,5.47119 z" id="path759" />
                        <path d="M 1515.4199,935.90625 V 980 h -5.5928 v -7.57861 q -3.5664,4.33642 -8.0244,6.5249 -4.4175,2.18848 -9.6861,2.18848 -9.3618,0 -16.0083,-6.76807 -6.6059,-6.80859 -6.6059,-16.53516 0,-9.52392 6.687,-16.29199 6.687,-6.76807 16.0893,-6.76807 5.4307,0 9.8077,2.31006 4.4174,2.31006 7.7407,6.93018 v -8.10547 z m -22.6954,4.33643 q -4.7417,0 -8.7539,2.35058 -4.0122,2.31006 -6.4033,6.5249 -2.3506,4.21485 -2.3506,8.91602 0,4.66064 2.3911,8.91602 2.3912,4.25537 6.4034,6.64648 4.0527,2.35059 8.6728,2.35059 4.6607,0 8.835,-2.35059 4.1743,-2.35059 6.4033,-6.36279 2.2695,-4.01221 2.2695,-9.0376 0,-7.65967 -5.0659,-12.80664 -5.0254,-5.14697 -12.4014,-5.14697 z" id="path761" />
                        <path d="m 1533.1303,919.5332 h 5.7144 v 16.37305 h 8.997 v 4.86328 h -8.997 V 980 h -5.7144 v -39.23047 h -7.7407 v -4.86328 h 7.7407 z" id="path763" />
                        <path d="m 1558.2978,917.75 q 1.9453,0 3.3232,1.37793 1.378,1.37793 1.378,3.32324 0,1.90479 -1.378,3.28272 -1.3779,1.37793 -3.3232,1.37793 -1.9048,0 -3.2827,-1.37793 -1.378,-1.37793 -1.378,-3.28272 0,-1.94531 1.378,-3.32324 1.3779,-1.37793 3.2827,-1.37793 z m -2.8369,18.15625 h 5.7143 V 980 h -5.7143 z" id="path765" />
                        <path d="m 1593.7997,934.77148 q 10.1724,0 16.8594,7.37598 6.0791,6.72754 6.0791,15.92725 0,9.24023 -6.4438,16.17041 -6.4033,6.88965 -16.4947,6.88965 -10.1318,0 -16.5756,-6.88965 -6.4034,-6.93018 -6.4034,-16.17041 0,-9.15918 6.0791,-15.88672 6.6871,-7.41651 16.8999,-7.41651 z m 0,5.55225 q -7.0517,0 -12.1176,5.22803 -5.066,5.22803 -5.066,12.64453 0,4.78223 2.3101,8.91601 2.3101,4.13379 6.2412,6.40332 3.9312,2.22901 8.6323,2.22901 4.7012,0 8.6324,-2.22901 3.9311,-2.26953 6.2412,-6.40332 2.31,-4.13378 2.31,-8.91601 0,-7.4165 -5.1064,-12.64453 -5.0659,-5.22803 -12.0772,-5.22803 z" id="path767" />
                        <path d="m 1627.2753,935.90625 h 5.6739 v 7.90283 q 3.4043,-4.53906 7.538,-6.76806 4.1338,-2.26954 8.9971,-2.26954 4.9443,0 8.7539,2.5127 3.8501,2.51269 5.6738,6.76807 1.8238,4.25537 1.8238,13.25244 V 980 h -5.6333 v -21.03369 q 0,-7.61914 -0.6485,-10.17236 -1.0131,-4.37696 -3.8095,-6.56543 -2.7964,-2.22901 -7.295,-2.22901 -5.1469,0 -9.2402,3.4043 -4.0527,3.4043 -5.3496,8.42969 -0.8105,3.28271 -0.8105,11.99609 V 980 h -5.6739 z" id="path769" />
                        <path d="m 1701.8456,920.38428 h 34.1646 v 5.87646 h -28.207 v 18.64258 h 27.9638 v 5.83594 h -27.9638 v 23.38428 h 27.9638 V 980 h -33.9214 z" id="path771" />
                        <path d="m 1740.3466,935.90625 h 6.8086 l 11.6719,16.33252 11.6719,-16.33252 h 6.8086 L 1762.2314,956.85889 1779.2123,980 h -6.8896 l -13.4956,-18.521 -13.293,18.521 h -6.768 l 16.6972,-23.14111 z" id="path773" />
                        <path d="m 1785.2914,935.90625 h 5.6333 v 8.10547 q 3.3638,-4.62012 7.7408,-6.93018 4.3769,-2.31006 9.7671,-2.31006 9.4023,0 16.0893,6.76807 6.687,6.76807 6.687,16.29199 0,9.72657 -6.6465,16.53516 -6.6059,6.76807 -15.9677,6.76807 -5.2686,0 -9.6861,-2.18848 -4.4175,-2.18848 -7.9839,-6.5249 v 23.70849 h -5.6333 z m 22.6954,4.33643 q -7.376,0 -12.4419,5.14697 -5.0254,5.14697 -5.0254,12.80664 0,5.02539 2.229,9.0376 2.2695,4.0122 6.4438,6.36279 4.2149,2.35059 8.8755,2.35059 4.5796,0 8.5918,-2.35059 4.0527,-2.39111 6.4439,-6.64648 2.3911,-4.25538 2.3911,-8.91602 0,-4.70117 -2.3911,-8.91602 -2.3506,-4.21484 -6.3628,-6.5249 -4.0122,-2.35058 -8.7539,-2.35058 z" id="path775" />
                        <path d="m 1878.9501,965.36963 4.7823,2.51269 q -2.3506,4.62012 -5.4307,7.45704 -3.0801,2.83691 -6.9302,4.33642 -3.8501,1.45899 -8.7134,1.45899 -10.7802,0 -16.8593,-7.05176 -6.0791,-7.09229 -6.0791,-16.0083 0,-8.38916 5.1469,-14.95459 6.5249,-8.34864 17.4673,-8.34864 11.2666,0 17.9942,8.55127 4.7822,6.03858 4.8632,15.07618 h -39.6357 q 0.1621,7.70019 4.9038,12.64453 4.7417,4.90381 11.7124,4.90381 3.3638,0 6.5249,-1.1753 3.2017,-1.17529 5.4307,-3.1206 2.229,-1.94532 4.8227,-6.28174 z m 0,-11.87451 q -1.1347,-4.53907 -3.3232,-7.2544 -2.148,-2.71533 -5.7144,-4.37695 -3.5664,-1.66162 -7.4975,-1.66162 -6.4844,0 -11.145,4.17431 -3.4043,3.03956 -5.147,9.11866 z" id="path777" />
                        <path d="m 1893.621,935.90625 h 5.7954 v 6.44385 q 2.5938,-3.80957 5.4712,-5.67383 2.8775,-1.90479 5.9981,-1.90479 2.3506,0 5.0254,1.49952 l -2.9585,4.78222 q -1.7832,-0.77002 -2.999,-0.77002 -2.837,0 -5.4712,2.35059 -2.6343,2.31006 -4.0122,7.21387 -1.0538,3.76904 -1.0538,15.23828 V 980 h -5.7954 z" id="path779" />
                        <path d="m 1925.6782,919.5332 h 5.7143 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 980 h -5.7143 v -39.23047 h -7.7408 v -4.86328 h 7.7408 z" id="path781" />
                        <path d="m 1994.0073,940.729 -3.6475,3.76905 q -4.5391,-4.41748 -8.8755,-4.41748 -2.7558,0 -4.7417,1.82373 -1.9453,1.82373 -1.9453,4.25537 0,2.14795 1.6211,4.09326 1.6211,1.98584 6.8086,4.66064 6.3223,3.28272 8.5918,6.32227 2.229,3.08008 2.229,6.93018 0,5.43066 -3.8096,9.1997 -3.8096,3.76905 -9.5239,3.76905 -3.8096,0 -7.2949,-1.66163 -3.4449,-1.66162 -5.7144,-4.57959 l 3.5664,-4.05273 q 4.3364,4.90381 9.1997,4.90381 3.4043,0 5.7954,-2.18848 2.3912,-2.18847 2.3912,-5.14697 0,-2.43164 -1.5806,-4.33643 -1.5806,-1.86425 -7.1328,-4.70117 -5.9575,-3.08008 -8.1055,-6.0791 -2.1479,-2.99902 -2.1479,-6.84912 0,-5.02539 3.4043,-8.34863 3.4448,-3.32325 8.6728,-3.32325 6.0791,0 12.2393,5.95752 z" id="path783" />
                        <path d="m 1999.3163,935.90625 h 5.9981 l 15.1572,33.96191 14.7114,-33.96191 h 6.0386 l -26.3022,60.22363 h -5.9981 l 8.5108,-19.45312 z" id="path785" />
                        <path d="m 2070.7255,940.729 -3.6474,3.76905 q -4.5391,-4.41748 -8.8755,-4.41748 -2.7559,0 -4.7417,1.82373 -1.9453,1.82373 -1.9453,4.25537 0,2.14795 1.6211,4.09326 1.6211,1.98584 6.8086,4.66064 6.3222,3.28272 8.5917,6.32227 2.2291,3.08008 2.2291,6.93018 0,5.43066 -3.8096,9.1997 -3.8096,3.76905 -9.5239,3.76905 -3.8096,0 -7.295,-1.66163 -3.4448,-1.66162 -5.7143,-4.57959 l 3.5664,-4.05273 q 4.3364,4.90381 9.1997,4.90381 3.4043,0 5.7954,-2.18848 2.3911,-2.18847 2.3911,-5.14697 0,-2.43164 -1.5806,-4.33643 -1.5805,-1.86425 -7.1328,-4.70117 -5.9575,-3.08008 -8.1054,-6.0791 -2.148,-2.99902 -2.148,-6.84912 0,-5.02539 3.4043,-8.34863 3.4448,-3.32325 8.6729,-3.32325 6.0791,0 12.2392,5.95752 z" id="path787" />
                        <path d="m 2085.7206,919.5332 h 5.7144 v 16.37305 h 8.9971 v 4.86328 h -8.9971 V 980 h -5.7144 v -39.23047 h -7.7407 v -4.86328 h 7.7407 z" id="path789" />
                        <path d="m 2146.0253,965.36963 4.7823,2.51269 q -2.3506,4.62012 -5.4307,7.45704 -3.0801,2.83691 -6.9302,4.33642 -3.8501,1.45899 -8.7134,1.45899 -10.7802,0 -16.8593,-7.05176 -6.0791,-7.09229 -6.0791,-16.0083 0,-8.38916 5.1469,-14.95459 6.5249,-8.34864 17.4673,-8.34864 11.2666,0 17.9942,8.55127 4.7822,6.03858 4.8632,15.07618 h -39.6357 q 0.1621,7.70019 4.9038,12.64453 4.7417,4.90381 11.7124,4.90381 3.3638,0 6.5249,-1.1753 3.2017,-1.17529 5.4307,-3.1206 2.229,-1.94532 4.8227,-6.28174 z m 0,-11.87451 q -1.1347,-4.53907 -3.3232,-7.2544 -2.148,-2.71533 -5.7144,-4.37695 -3.5664,-1.66162 -7.4975,-1.66162 -6.4844,0 -11.145,4.17431 -3.4043,3.03956 -5.147,9.11866 z" id="path791" />
                        <path d="m 2162.8036,935.90625 h 5.6334 v 7.61914 q 2.9584,-4.21484 6.0791,-6.16016 4.2959,-2.59375 9.0375,-2.59375 3.2017,0 6.0792,1.25635 2.8774,1.25635 4.7011,3.36377 1.8238,2.0669 3.1612,5.95752 2.8369,-5.1875 7.0922,-7.8623 4.2554,-2.71534 9.1592,-2.71534 4.5796,0 8.065,2.31006 3.5258,2.31006 5.228,6.44385 1.7021,4.13379 1.7021,12.40137 V 980 h -5.7954 v -24.07324 q 0,-7.09229 -1.0132,-9.72656 -1.0131,-2.67481 -3.4853,-4.2959 -2.4316,-1.6211 -5.8359,-1.6211 -4.1338,0 -7.5787,2.43164 -3.4043,2.43164 -4.9848,6.48438 -1.5806,4.05273 -1.5806,13.53613 V 980 h -5.6333 v -22.57373 q 0,-7.98389 -1.0132,-10.8208 -0.9726,-2.87744 -3.4448,-4.57959 -2.4722,-1.74268 -5.8765,-1.74268 -3.9311,0 -7.3759,2.39112 -3.4043,2.35058 -5.066,6.36279 -1.621,4.01221 -1.621,12.27978 V 980 h -5.6334 z" id="path793" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
